import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgUE from "../img/icon/projekty_pl.png";

export default function Header(props) {



    return (
            <React.Fragment>
                <section id="collaboration" class="collaboration ourcoffees firstSection">
                    <h1>{i18next.t('UEH1')}</h1>
<br/>
                    <div><img loading="lazy" src={ImgUE} alt="Instanta Custom Products" /></div><br/>

                    <p><b>Dofinansowanie z Funduszu Ubezpieczeń Społecznych</b><br/>
Program dofinansowania płatników składek w ramach prewencji wypadkowej.<br/><br/>
Tytuł projektu:<br/>
Poprawa warunków pracy poprzez zmniejszenie stężenia pyłu<br/>
kawowego i obniżenie poziomu ryzyka zawodowego w firmie Instanta Sp. z o.o.<br/><br/>
Całkowita wartość projektu: 347 782,50  zł<br/>
Dofinansowanie: 277 980,00 zł<br/><br/>
Zmniejszenie oddziaływania uciążliwych czynników na stanowisku pracy Operator-pakowacz linia 8-9.<br/>
Data podpisania umowy: 12/2023<br/>
                    </p>

                </section>


            </React.Fragment>

            )


}